import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import {
  LAMPORTS_PER_SOL, PublicKey, SystemProgram, Transaction,
} from '@solana/web3.js';
import bs58 from 'bs58';

import { useNotify } from './Notify';

export const useSignTransaction = () => {
  const { connection } = useConnection();
  const { publicKey, signTransaction } = useWallet();
  const notify = useNotify();

  const onClick = async (lamports: number, publicAddress: string) => {
    try {
      if (!publicKey) throw new Error('Wallet not connected!');
      if (!signTransaction) throw new Error('Wallet does not support transaction signing!');

      const {
        value: { blockhash },
      } = await connection.getLatestBlockhashAndContext();

      const lamportsI = LAMPORTS_PER_SOL * lamports;
      const transaction = new Transaction().add(
        SystemProgram.transfer({
          fromPubkey: publicKey,
          toPubkey: new PublicKey(publicAddress),
          lamports: lamportsI,
        }),
      );
      transaction.recentBlockhash = blockhash;
      transaction.feePayer = publicKey;

      const transactionResponse = await signTransaction(transaction);
      if (!transactionResponse.signature) throw new Error('Transaction not signed!');
      const signature = bs58.encode(transactionResponse.signature);
      notify('info', `Transaction signed: ${signature}`);
      if (!transactionResponse.verifySignatures()) throw new Error(`Transaction signature invalid! ${signature}`);
      notify('success', `Transaction signature valid! ${signature}`);
    } catch (error: any) {
      notify('error', `Transaction signing failed! ${error?.message}`);
      throw error;
    }
  };

  return onClick;
};
