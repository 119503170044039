import React from 'react';

import { useThemeState } from '../../store/themeState';
import {
  Container, FaqItemDescrib, FaqItemTitle, FaqList, FaqListItem, Section, Title,
} from './styles';

export const Faq: React.FC = () => {
  const themeMode = useThemeState((state) => state.mode);

  return (
    <Section style={{ background: themeMode === 'light' ? 'var(--main-white)' : 'var(--main-black)' }}>
      <Container>
        <Title style={{ color: themeMode === 'light' ? 'var(--text-black)' : 'var(--text-white)' }}>FAQ</Title>
        <FaqList>
          <FaqListItem>
            <FaqItemTitle>WHY SHOULD I TRUST YOU?</FaqItemTitle>
            <FaqItemDescrib>
              <p>
                The app has over 600,000 dedicated users, our team includes over 20 top-level experts, we've been in the market since 2017, and we are supported by major influencers in the crypto space.
              </p>
            </FaqItemDescrib>
          </FaqListItem>
          <FaqListItem>
            <FaqItemTitle>How Does Staking Work?</FaqItemTitle>
            <FaqItemDescrib>
              <p>
                We use advanced liquidity staking algorithms to maximize returns. When users send funds to the Staking Fund, they add liquidity to trading pairs through our providers. Users earn not only from the liquidity provided but also from internal payments within the webapp. 30% of all revenue generated by the game is distributed into the Reward Staking Fund, providing additional earnings for stakers.
              </p>
            </FaqItemDescrib>
          </FaqListItem>
          <FaqListItem>
            <FaqItemTitle>How Does the Referral Earning System Work?</FaqItemTitle>
            <FaqItemDescrib>
              <p>
                If your referrals make payments within the app, you earn up to 20% of the amount of their purchases. This incentivizes users to invite others and grow the community.
                <br />
                <br />
                Purchases are made to boost the earning potential of the in-game currency, $PUNKS. The earned $PUNKS can later be burned to receive airdrops from new projects, offering players valuable rewards.
              </p>
            </FaqItemDescrib>
          </FaqListItem>
          <FaqListItem>
            <FaqItemTitle>How Else Is This Token Used?</FaqItemTitle>
            <FaqItemDescrib>
              <p>All B2B advertising purchases within the app are exclusively paid for using this token, ensuring its utility and demand within the ecosystem.</p>
            </FaqItemDescrib>
          </FaqListItem>
        </FaqList>
      </Container>
    </Section>
  );
};
