import { setErrorsStatePartialy } from '../errorsState';
import { setLoadingStatePartialy } from '../loadingState/actions';
import { setUserStatePartialy } from './actions';
import { CACHE_LIFE_TIME_MC } from './constants';
import { useUserState } from './store';
import { UserStore } from './types';

import {
  createUser as createUserApi,
  getTokenPrice as getTokenPriceApi,
  getUserBalance as getUserBalanceApi,
  updateUserEmail as updateUserEmailApi,
} from '@/api';
import { solanaSignMessage, SolanaSignMessageReq } from '@/api/signMessage';
import { TOKEN_ADDRESS } from '@/config';

export const createUser = async (needCache: boolean = true) => {
  const { lastFetched } = useUserState.getState();
  if (needCache && lastFetched + CACHE_LIFE_TIME_MC > Date.now()) {
    return;
  }
  setLoadingStatePartialy({ user: true });
  setErrorsStatePartialy({ user: false });
  try {
    useUserState.setState({
      lastFetched: Date.now(),
    });
    const res = await createUserApi();

    useUserState.setState({
      user: res.data,
    });
  } catch {
    setErrorsStatePartialy({ user: true });
  } finally {
    setLoadingStatePartialy({ user: false });
  }
};

export const createWalletAccount = async (req: SolanaSignMessageReq) => {
  const res = await solanaSignMessage(req);

  setUserStatePartialy({
    user: {
      ...useUserState.getState().user,
      accounts: res.accounts,
    } as UserStore['user'],
  });
};

export const updateUserEmail = async (email: string) => {
  setLoadingStatePartialy({ user: true });
  setErrorsStatePartialy({ user: false });
  try {
    const { user } = useUserState.getState();
    if (!user?.id) {
      throw Error('empty user');
    }
    await updateUserEmailApi(email, user.id);
  } catch {
    setErrorsStatePartialy({ user: true });
  } finally {
    setLoadingStatePartialy({ user: false });
  }
};

export const getUserBalance = async (publicKey: string) => {
  setLoadingStatePartialy({ userBalance: true });
  setErrorsStatePartialy({ userBalance: false });
  try {
    if (!publicKey) {
      throw Error('empty public key');
    }
    const res = await getUserBalanceApi(publicKey);
    setUserStatePartialy({
      deposit: res.data.SOL,
    });
  } catch {
    setErrorsStatePartialy({ userBalance: true });
  } finally {
    setLoadingStatePartialy({ userBalance: false });
  }
};

export const getTokenPrice = async () => {
  setLoadingStatePartialy({ tokenPrice: true });
  setErrorsStatePartialy({ tokenPrice: false });
  try {
    const res = await getTokenPriceApi(TOKEN_ADDRESS);
    setUserStatePartialy({
      token_price: res.data.token_price,
    });
  } catch {
    setErrorsStatePartialy({ tokenPrice: true });
  } finally {
    setLoadingStatePartialy({ tokenPrice: false });
  }
};
