import React, { useState } from 'react';

import intro from '../../assets/intro.png';
import { useThemeState } from '../../store/themeState';
import { Tokenomic } from '../tokenomic';
import {
  Body, Container, Content, H3, Img, Link, Links, Text, Title,
} from './styles';

export const Intro: React.FC = () => {
  const themeMode = useThemeState((state) => state.mode);

  const [isTokenomicOpen, setTokenomicOpen] = useState<boolean>(false);

  return (
    <div style={{ background: themeMode === 'light' ? 'var(--main-white)' : 'var(--main-black)' }}>
      <Container>
        <Img alt="pizzas" src={intro} />
        <Content>
          <Title style={{ color: themeMode === 'light' ? 'var(--text-black)' : 'var(--text-white)' }}>
            INVEST IN $GONG NOW!
          </Title>
          <Body>
            <Text style={{ color: themeMode === 'light' ? 'var(--text-black)' : 'var(--text-white)' }}>
              <H3>WePunks Today</H3>
              <p>
                Punks is currently one of the largest Web3 GameFi applications on the market, boasting an user base of over 600,000. $GONG is a primary currency within the WePunks application. This currency features a limited issuance and a cyclical tokenomics model designed to foster community growth, earning potential, and overall ecosystem development.
              </p>
            </Text>

            <Text style={{ color: themeMode === 'light' ? 'var(--text-black)' : 'var(--text-white)' }}>
              <H3>WePunks Tomorrow</H3>
              <p>
                Punks aims to become the leading Web3 GameFi product by prioritizing user-friendliness, clear mechanics, earning opportunities for users, and transparency in timelines and plans.
              </p>
            </Text>

            <Text style={{ color: themeMode === 'light' ? 'var(--text-black)' : 'var(--text-white)' }}>
              <H3>
                WePunks Day After Tomorrow
              </H3>
              <p>
                Our ambitious goal is to create a new lifestyle through meaningful utility. This includes developing an innovative clothing brand and a sophisticated, technologically advanced blockchain with cutting-edge algorithms. To sum up our mission in one sentence: Play, Stake, Hold, Share, Live, and other aspects of existence to Earn – a Blockchain controlled by AI.
              </p>
              <p>Don&apos;t just witness our journey—become an integral part of it.</p>
              <p>Together, we will revolutionize the world! 🫶🎸✊</p>
            </Text>
          </Body>
          <Links>
            <Link href="https://t.me/WePunks" target="_blank">telegram</Link>
            <Link href="https://x.com/we_punks" target="_blank">twitter(x)</Link>
            <Link onClick={() => setTokenomicOpen(true)}>tokenomics</Link>
          </Links>
          <Tokenomic isOpen={isTokenomicOpen} onClose={() => setTokenomicOpen(false)} />
        </Content>
      </Container>
    </div>
  );
};
