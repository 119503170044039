import { create } from 'zustand';

import { ErrorsStore } from './types';

export const ERRORS_DEFAULT_STATE = {
  user: false,
  connectWallet: false,
  transaction: false,
  tokenPrice: false,
  userBalance: false,
};
export const useErrorsState = create<ErrorsStore>(() => ERRORS_DEFAULT_STATE);
