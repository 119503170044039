import { useEffect, useState } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { useWalletModal } from '@solana/wallet-adapter-react-ui';
import bs58 from 'bs58';

import { useNotify } from '@/components';
import {
  createWalletAccount, setErrorsStatePartialy, setLoadingStatePartialy, useUserState,
} from '@/store';

export const SignInListener = () => {
  const {
    publicKey, connected, signMessage, connecting,
  } = useWallet();
  const walletModal = useWalletModal();
  const userAccounts = useUserState((state) => state.user?.accounts);

  const notify = useNotify();
  const user = useUserState((state) => state.user);
  const [rawConnectWalletLoading, setRawConnectWalletLoading] = useState(false);
  const isSignIn = userAccounts && userAccounts.some((account) => account.public_key === publicKey?.toBase58());
  const handleSignIn = async () => {
    try {
      setRawConnectWalletLoading(true);
      setErrorsStatePartialy({ connectWallet: false });
      setLoadingStatePartialy({ connectWallet: true });

      if (!connected) {
        walletModal.setVisible(true);
      }

      try {
        if (!publicKey) throw new Error('Wallet not connected!');
        if (!signMessage) throw new Error('Wallet does not support message signing!');

        const messageText = `${
          window.location.host
        } wants you to sign in with your Solana account:\n${publicKey.toBase58()}\n\nPlease sign in.`;

        const message = new TextEncoder().encode(messageText);
        const signature = await signMessage(message);

        if (!user?.id) {
          throw Error('empty user');
        }
        await createWalletAccount({
          signature: bs58.encode(signature),
          message: bs58.encode(message),
          publicKey,
          userId: user?.id,
        });
        notify('success', `Message signature: ${bs58.encode(signature)}`);
      } catch (error: any) {
        setErrorsStatePartialy({ connectWallet: true });
        notify('error', `Sign Message failed: ${error?.message}`);
      }
    } catch (error) {
      setErrorsStatePartialy({ connectWallet: true });
    } finally {
      setRawConnectWalletLoading(false);
      setLoadingStatePartialy({ connectWallet: false });
    }
  };

  useEffect(() => {
    setLoadingStatePartialy({ connectWallet: rawConnectWalletLoading || connecting });
  }, [connecting, rawConnectWalletLoading]);

  useEffect(() => {
    if (connected && user?.id && !isSignIn) {
      handleSignIn();
    }
  }, [connected, user]);

  return <></>;
};
