import React from 'react';

import guild from '../../assets/icons/guild.png';
import tg from '../../assets/icons/tg.png';
import tw from '../../assets/icons/tw.png';
import wc from '../../assets/icons/wc.png';
import { useThemeState } from '../../store/themeState';
import {
  Block,
  Container,
  FooterWrapper,
  Page,
  Soc,
  SocItem,
  SocLink,
  Text,
  TextBottom,
  TextBottomWrap,
  Title,
} from './styles';

export const Footer: React.FC = () => {
  const themeMode = useThemeState((state) => state.mode);

  return (
    <Page style={{ background: themeMode === 'light' ? 'var(--main-white)' : 'var(--main-black)' }}>
      <Container>
        <FooterWrapper>
          <Block>
            <Title>(Open for Partnerships)</Title>
            <Text>Rest of you - we&apos;re closed</Text>
            <Text>[no reservations]</Text>
          </Block>
          <Soc>
            <SocItem>
              <SocLink href="https://guild.xyz/punks" target="_blank">
                {' '}
                Guild
                <img alt="Guild" src={guild} />
              </SocLink>
            </SocItem>
            <SocItem>
              <SocLink href="https://t.me/Punks_Gamebot" target="_blank">
                {' '}
                Telegram
                <img alt="Telegram" src={tg} />
              </SocLink>
            </SocItem>
            <SocItem>
              <SocLink href="https://warpcast.com/~/channel/onchainpunks" target="_blank">
                {' '}
                Warpcast
                <img alt="Warpcast" src={wc} />
              </SocLink>
            </SocItem>
            <SocItem>
              <SocLink href="https://twitter.com/we_punks" target="_blank">
                {' '}
                Twitter
                <img alt="Twitter" src={tw} />
              </SocLink>
            </SocItem>
          </Soc>
          <Block>
            <Title>Working days</Title>
            <Text>For punks - no weekends</Text>
          </Block>
        </FooterWrapper>
      </Container>
    </Page>
  );
};
