const {
  VITE_API_URL: API_URL,
  VITE_SOLANA_TOKEN_PROGRAM_2022_ID: SOLANA_TOKEN_PROGRAM_2022_ID,
  VITE_SOLANA_TOKEN_PROGRAM_ID: SOLANA_TOKEN_PROGRAM_ID,
  VITE_TIME_PUBLIC_ADDRESS: TIME_PUBLIC_ADDRESS,
  VITE_TOKEN_ADDRESS: TOKEN_ADDRESS,
} = import.meta.env;

export {
  TOKEN_ADDRESS, API_URL, SOLANA_TOKEN_PROGRAM_2022_ID, SOLANA_TOKEN_PROGRAM_ID, TIME_PUBLIC_ADDRESS,
};
