import { FC, useEffect, useState } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { useWalletModal } from '@solana/wallet-adapter-react-ui';

import {
  AddressContainer,
  Btn,
  Input,
  InputContainer,
  Label,
  MaskedStyledInput,
  SubTitle,
  TabContentItem,
  TabContentItemTop,
} from './styles';

import { useNotify, useSendTransaction, useSignTransaction } from '@/components';
import { TIME_PUBLIC_ADDRESS } from '@/config';
import { useCopyToClipboard } from '@/hooks/useCopyToClipboard';
import {
  getUserBalance, setErrorsStatePartialy, setLoadingStatePartialy, setOnboardingStep, useUserState,
} from '@/store';

type TabContentProps = {
  Icon: FC
  handleInputChange: (value: string) => void
  inputValue: string
  name: string
};

export type Steps = 'default' | 'has-wallet';
export const step: Steps = 'default';

const IS_FAILDER = true;
export const TabContentStandart: FC<TabContentProps> = ({
  inputValue, handleInputChange, name, Icon,
}) => {
  const handSignTransation = useSignTransaction();
  const handSendTransation = useSendTransaction();

  const walletModal = useWalletModal();

  const userAccounts = useUserState((state) => state.user?.accounts);
  const [step, setStep] = useState('default');
  const { publicKey, connected } = useWallet();
  const [copy] = useCopyToClipboard();
  const notify = useNotify();

  const [isSendOrder, setSendOrder] = useState<boolean>(false);
  const resetStatus = () => {
    setSendOrder(false);
  };

  useEffect(() => {
    if (isSendOrder) {
      return resetStatus;
    }
  });
  useEffect(() => {
    const isSignIn = userAccounts && userAccounts.some((account) => account.public_key === publicKey?.toBase58());
    if (connected && isSignIn) {
      setStep('has-wallet');

      return;
    }
    setStep('default');
  });

  return (
    <>
      {IS_FAILDER
        ? (
          <a className="flex gap-1" href="https://www.dextools.io/app/en/solana/pair-explorer/DwKfsQ54n9mb7vSa56mvAthbwhQPep36DQE2ZSaGHGdQ?t=1722611124613">
            We will add it soon, so you can use DexTool for now (Press to buy)
          </a>
        )
        : (
          <TabContentItem className="active">
            <TabContentItemTop>
              <SubTitle>You&apos;re paying</SubTitle>
            </TabContentItemTop>
            <InputContainer>
              <MaskedStyledInput
                mask={Number}
                max="200"
                min="0.1"
                onAccept={handleInputChange}
                radix="."
                value={inputValue}
              />
              <Label>
                <div className="svgWrap">
                  <Icon />
                </div>
                {name}
              </Label>
            </InputContainer>
            {step === 'has-wallet' && publicKey && (
            <AddressContainer>
              <SubTitle>Your Solana address</SubTitle>
              <Input
                readOnly
                className="cursor-pointer"
                value={publicKey?.toBase58()}
                onClick={async () => {
                  try {
                    await copy(publicKey?.toBase58() || '');
                    notify('success', 'success copy :)');
                  } catch {
                    notify('error', 'Error while copy');
                  }
                }}
              />
            </AddressContainer>
            )}
            {/* {step === 'has-wallet' && <Btn onClick={() => setSendOrder(true)}>Pending approval</Btn>} */}
            {step === 'has-wallet' && (
            <Btn
              onClick={() => {
                setLoadingStatePartialy({ transaction: true });
                handSignTransation(Number(inputValue), TIME_PUBLIC_ADDRESS)
                  .then(() => handSendTransation(Number(inputValue), TIME_PUBLIC_ADDRESS)
                    .then(() => {
                      setLoadingStatePartialy({ transaction: false });
                      setOnboardingStep('email-send');
                      getUserBalance(TIME_PUBLIC_ADDRESS);
                    })
                    .catch(() => setErrorsStatePartialy({ transaction: true })))
                  .catch(() => setErrorsStatePartialy({ transaction: true }));
              }}
            >
              Invest
            </Btn>
            )}
            {step === 'default' && <Btn onClick={() => walletModal.setVisible(true)}>CONNECT WALLET</Btn>}
            {step === 'default' && (
            <a className="mt-4 text-center text-[14px] text-[#231F20E5]" href="https://phantom.app/" rel="noreferrer" target="_blank">I don’t have solana wallet</a>
            )}
          </TabContentItem>
        )}
    </>
  );
};
