import React, { ReactNode, useCallback } from 'react';
import { enqueueSnackbar, useSnackbar, VariantType } from 'notistack';

import { Notification, StyledLaunchIcon, StyledLink } from './styles';

// Interface for Notification props
interface NotificationProps {
  children: ReactNode
}

const NotificationComponent: React.FC<NotificationProps> = ({ children }) => <Notification>{children}</Notification>;

// Interface for StyledLink props
interface StyledLinkProps {
  children: ReactNode
  href: string
}

const StyledLinkComponent: React.FC<StyledLinkProps> = ({ href, children }) => (
  <StyledLink href={href} rel="noopener noreferrer" target="_blank">
    {children}
    <StyledLaunchIcon />
  </StyledLink>
);

export function useNotify() {
  const { enqueueSnackbar } = useSnackbar();

  return useCallback(
    (variant: VariantType, message: string, signature?: string) => {
      enqueueSnackbar(
        <NotificationComponent>
          {message}
          {signature && (
            <StyledLinkComponent href={`https://explorer.solana.com/tx/${signature}?cluster=testnet`}>
              Transaction
            </StyledLinkComponent>
          )}
        </NotificationComponent>,
        { variant },
      );
    },
    [enqueueSnackbar],
  );
}

export const handNotify = (variant: VariantType, message: string, signature?: string) => {
  enqueueSnackbar(
    <NotificationComponent>
      {message}
      {signature && (
        <StyledLinkComponent href={`https://explorer.solana.com/tx/${signature}?cluster=testnet`}>
          Transaction
        </StyledLinkComponent>
      )}
    </NotificationComponent>,
    { variant },
  );
};
