import React, { useState } from 'react';
import { useWalletModal } from '@solana/wallet-adapter-react-ui';
import { useNavigate } from '@tanstack/react-router';

import guild from '../../assets/icons/guild.png';
import tg from '../../assets/icons/tg.png';
import tw from '../../assets/icons/tw.png';
import wc from '../../assets/icons/wc.png';
import { Soc, SocItem, SocLink } from '../footer/styles';
import {
  Button, ButtonPanel, Text, Title,
} from './styles';

import ConfirmIcon from '@/assets/icons/confirm.svg?react';
import ShareIcon from '@/assets/share.svg?react';
import Popup from '@/components/Popup';
import { TIME_PUBLIC_ADDRESS } from '@/config';
import {
  setOnboardingStep, updateUserEmail, useLoadingState, useOnboardState, useUserState,
} from '@/store';

export interface ModalProps {
  isOpen: boolean
  onClose: () => void
  setActiveTab: (method: string) => void
}

const SelectMethodModal: React.FC = () => {
  const walletModal = useWalletModal();

  return (
    <>
      <Title>We accept on multiple blockchains.</Title>
      <Text>But the tokens will be sent to your solana wallet. And please connect it.</Text>
      <ButtonPanel>
        <Button onClick={() => setOnboardingStep(undefined)}>connect later</Button>
        <Button
          className="white"
          onClick={() => {
            setOnboardingStep(undefined);
            walletModal.setVisible(true);
          }}
        >
          connect wallet
        </Button>
      </ButtonPanel>
    </>
  );
};

const FirstModal: React.FC = () => {
  const navigate = useNavigate();
  const handleNoClick = () => {
    navigate({ to: '/' });
  };

  return (
    <>
      <Title>welcome,punks!🍕</Title>
      <Text>
        By entering, you will be one of the earliest adepts of PUNKS $TIME project.
        <br />
        <br />
        Are you ready to invest in $TIME?
      </Text>
      <ButtonPanel>
        <Button onClick={handleNoClick}>no</Button>
        <Button className="white" onClick={() => setOnboardingStep('pay-info')}>
          yes!
        </Button>
      </ButtonPanel>
    </>
  );
};

export const SuccessWalletConnectNotification = () => (
  <div className="flex items-center gap-3 p-3">
    <ConfirmIcon className="flex h-[44px] w-[44px] items-center justify-center" />
    <div className="flex flex-col gap-[2px]">
      <span className="h3-bold uppercase">wallet connected</span>
      <span className="p3 opacity-50">Connected to wallet Gxjb... G8hY</span>
      <span className="p3">Auto Confirm is available in Phantom.</span>
    </div>
  </div>
);

export const EmailSendModal = () => {
  const [email, setEmail] = useState('');
  const transactionSol = useUserState((state) => state.transactionSol);

  return (
    <div className="flex flex-col gap-6 text-primaryDark">
      <div className="flex flex-col gap-3">
        <span className="h3-bold uppercase">bought $time&apos;s</span>
        <span className="p3">Enter Email to let us notify you of about claiming</span>
      </div>

      <div className="p2-medium flex h-[64px] items-center justify-center rounded-lg border border-solid border-[#F7F7ED26] bg-[#231F20]">
        <input
          className="h-full  w-full rounded-lg p-3 placeholder:text-center"
          onInput={(event) => setEmail((event.target as HTMLInputElement).value)}
          placeholder="YOUR EMAIL"
          type="email"
          value={email}
        />
      </div>
      <div className="flex items-center gap-2">
        <Button
          className="flex items-center justify-center gap-3"
          onClick={() => {
            window.location.replace(
              `https://x.com/intent/post?text=I+invested+${transactionSol}+%24SOL+in+%24TIME%21%0D%0A%0D%0ASend+Min.+0.1+SOL%2C+Max.+200+SOL+to+%F0%9F%91%87%0D%0A%0D%0A${TIME_PUBLIC_ADDRESS}%0D%0A%0D%0AUse+website+https://wepunks.wtf/+to+verify+presale+wallet%0D%0A%0D%0A%E2%9A%A0%EF%B8%8FRecheck+in+official+twitter+profile+%40we_punks%21`,
            );
          }}
        >
          <ShareIcon />
          share
        </Button>
        <Button
          className="white"
          onClick={() => {
            if (email) {
              updateUserEmail(email).then(() => setOnboardingStep('last-chance'));

              return;
            }
            setOnboardingStep('last-chance');
          }}
        >
          okey,cool
        </Button>
      </div>
    </div>
  );
};

export const Onboarding: React.FC = () => {
  const onboardingStep = useOnboardState((state) => state.step);
  const userUpdateLoading = useLoadingState((state) => state.user);
  const transactionSol = useUserState((state) => state.transactionSol);
  const handleClose = () => setOnboardingStep(undefined);

  return (
    <>
      <Popup isOpen={onboardingStep === 'pay-info'} setIsOpen={handleClose}>
        <SelectMethodModal />
      </Popup>
      <Popup isLoading={userUpdateLoading} isOpen={onboardingStep === 'email-send'} setIsOpen={handleClose}>
        <EmailSendModal />
      </Popup>

      <Popup isLoading={userUpdateLoading} isOpen={onboardingStep === 'last-chance'} setIsOpen={handleClose}>
        <div className="flex flex-col gap-6 text-primaryDark">
          <div className="flex flex-col gap-3">
            <span className="h3-bold uppercase">last chance</span>
            <span className="p3">
              Are you sure you don&apos;t want to share with your friends?
              <br />
              You can get a bonus during Airdrop!
            </span>
          </div>

          <div className="flex items-center gap-2">
            <Button onClick={() => setOnboardingStep('thank-you')}>No, thanks</Button>
            <Button
              className="white flex items-center justify-center gap-3"
              onClick={() => {
                window.location.replace(
                  `https://x.com/intent/post?text=I+invested+${transactionSol}+%24SOL+in+%24TIME%21%0D%0A%0D%0ASend+Min.+0.1+SOL%2C+Max.+200+SOL+to+%F0%9F%91%87%0D%0A%0D%0A${TIME_PUBLIC_ADDRESS}%0D%0A%0D%0AUse+website+https://wepunks.wtf/+to+verify+presale+wallet%0D%0A%0D%0A%E2%9A%A0%EF%B8%8FRecheck+in+official+twitter+profile+%40we_punks%21`,
                );
              }}
            >
              <ShareIcon />
              share
            </Button>
          </div>
        </div>
      </Popup>

      <Popup isLoading={userUpdateLoading} isOpen={onboardingStep === 'thank-you'} setIsOpen={handleClose}>
        <div className="flex flex-col gap-6 text-primaryDark">
          <div className="flex flex-col gap-3">
            <span className="h3-bold uppercase">Thank you!</span>
            <span className="p3">Airdrop - in 15 days, subscribe to our social media to stay up to date!</span>
          </div>

          <Soc className="!m-0 !justify-start !gap-4">
            <SocItem className="!m-0 !h-[32px] !w-[32px]">
              <SocLink href="https://guild.xyz/punks" target="_blank">
                {' '}
                Guild
                <img alt="Guild" src={guild} />
              </SocLink>
            </SocItem>
            <SocItem className="!m-0 !h-[32px] !w-[32px]">
              <SocLink href="https://t.me/Punks_Gamebot" target="_blank">
                {' '}
                Telegram
                <img alt="Telegram" src={tg} />
              </SocLink>
            </SocItem>
            <SocItem className="!m-0 !h-[32px] !w-[32px]">
              <SocLink href="https://warpcast.com/~/channel/onchainpunks" target="_blank">
                {' '}
                Warpcast
                <img alt="Warpcast" src={wc} />
              </SocLink>
            </SocItem>
            <SocItem className="!m-0 !h-[32px] !w-[32px]">
              <SocLink href="https://twitter.com/we_punks" target="_blank">
                {' '}
                Twitter
                <img alt="Twitter" src={tw} />
              </SocLink>
            </SocItem>
          </Soc>
        </div>
      </Popup>
    </>
  );
};
