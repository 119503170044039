import { v4 as uuidv4 } from 'uuid';

import { useNotifications } from './store';
import { Notification } from './types';

export const addNotification = ({
  content,
  mode,
}: {
  content: Notification['content']
  mode: Notification['mode']
}) => {
  const newNotification: Notification = {
    id: uuidv4(),
    content,
    animation: 'in',
    mode,
  };
  useNotifications.setState((state) => ({
    list: [...state.list, newNotification],
  }));

  setTimeout(() => {
    useNotifications.setState((state) => ({
      list: state.list.map((item) => (item.id === newNotification.id ? { ...item, animation: 'out' } : item)),
    }));

    setTimeout(() => {
      useNotifications.setState((state) => ({
        list: state.list.filter((item) => item.id !== newNotification.id),
      }));
    }, 300);
  }, 2700);
};

export const removeNotification = (id: Notification['id']) => {
  useNotifications.setState((state) => ({
    list: state.list.map((item) => (item.id === id ? { ...item, animation: 'out' } : item)),
  }));

  setTimeout(() => {
    useNotifications.setState((state) => ({
      list: state.list.filter((item) => item.id !== id),
    }));
  }, 300);
};
