import { useLoadingState } from './store';
import { LoadingState } from './types';

export const setLoadingStatePartialy = (newState: Partial<LoadingState>) => {
  useLoadingState.setState((state) => ({
    ...state,
    ...newState,
  }));
};

export const clearLoadingState = () => {
  useLoadingState.setState({
    connectWallet: false,
  });
};
