import React, { useCallback, useEffect, useRef } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { PublicKey } from '@solana/web3.js';

import { TIME_PUBLIC_ADDRESS } from '@/config';
import {
  getTokenPrice, getUserBalance, setOnboardingStep, useUserState,
} from '@/store';
import { Chart } from '@/widgets/chart';
import { Faq } from '@/widgets/faq';
import { Intro } from '@/widgets/intro';
import { Order } from '@/widgets/order';
import { SignInListener } from '@/widgets/SignInListener';

export const PresalePage: React.FC = () => {
  const { wallet, publicKey } = useWallet();
  const timerId = useRef<NodeJS.Timeout | null>(null);
  const publicKeyRef = useRef<PublicKey | null>();
  const deposit = useUserState((state) => state.deposit);

  useEffect(() => {
    typeof window.fbq === 'function' && window.fbq('track', 'PageView');
  }, []);
  useEffect(() => {
    if (wallet) {
      setOnboardingStep(undefined);
    }
    // setOnboardingStep('pay-info');
  }, []);

  useEffect(() => {
    publicKeyRef.current = publicKey;
    if (!deposit) {
      getUserBalance(TIME_PUBLIC_ADDRESS);
    }
  }, [publicKey]);

  const oneTickGetInfo = useCallback(() => {
    getUserBalance(TIME_PUBLIC_ADDRESS);
    getTokenPrice();
    timerId.current = setTimeout(() => {
      oneTickGetInfo();
    }, 10000);
  }, [publicKey]);

  useEffect(() => {
    oneTickGetInfo();

    return () => {
      timerId.current && clearTimeout(timerId.current);
    };
  }, []);

  return (
    <>
      <Intro />
      {/* <Chart /> */}
      <Order />
      <Faq />
      <SignInListener />
    </>
  );
};
