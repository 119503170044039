import React, { useEffect, useState } from 'react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { useRouterState } from '@tanstack/react-router';
import classNames from 'classnames';

import darkTheme from '../../assets/icons/dark-theme.svg';
import lightTheme from '../../assets/icons/light-theme.png';
import logo from '../../assets/icons/logo.svg';
import logoWhite from '../../assets/icons/punks-white.svg';
import { setThemeMode, useThemeState } from '../../store/themeState';
import { Tokenomic } from '../tokenomic';
import {
  adapterWallletButton,
  Buttons,
  Container,
  LightImg,
  Link,
  Logo,
  MenuButton,
  Nav,
  Page,
  Right,
  ThemeButton,
} from './styles';

import CloseIcon from '@/assets/icons/close.svg?react';
import MenuIcon from '@/assets/icons/menu.svg?react';
import { useLoadingState } from '@/store';

const StyledButton = adapterWallletButton;

export const Header: React.FC = () => {
  const routerState = useRouterState();
  const themeMode = useThemeState((state) => state.mode);

  const [isOpen, setIsOpen] = useState(false);
  const [isTokenomicOpen, setTokenomicOpen] = useState<boolean>(false);
  const transactionLoading = useLoadingState((state) => state.transaction);
  const connectWalletLoading = useLoadingState((state) => state.connectWallet);

  function handleSetTheme(theme: 'dark' | 'light') {
    setThemeMode(theme);
  }

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [isOpen]);

  return (
    <Page
      style={{
        background: themeMode === 'light' ? 'var(--main-white)' : 'var(--main-black)',
        borderBottom: themeMode === 'light' ? '1px solid var(--addable-gray)' : '1px solid var(--main-black)',
        color: themeMode === 'light' ? 'var(--main-black)' : 'var(--main-white)',
      }}
    >
      <Container>
        <Logo alt="punks" src={themeMode === 'light' ? logo : logoWhite} />
        {/* TODO VARS */}
        <Right className={isOpen ? 'open' : ''}>
          <Nav>
            <Link to="/">Home</Link>
            <Link to="https://wepunks.gitbook.io">Docks</Link>
            <Link onClick={() => setIsOpen(false)} to="/token">
              Token Info
            </Link>
          </Nav>
          <Buttons>
            {routerState.location.pathname === '/token' && (
              <div
                data-loading={transactionLoading || connectWalletLoading}
                className={classNames({
                  'pointer-events-none animate-pulse *:!pointer-events-none [&>*]:!pointer-events-none':
                    transactionLoading || connectWalletLoading,
                })}
              >
                <WalletMultiButton />
              </div>
            )}
            {themeMode === 'light' && (
              <ThemeButton onClick={() => handleSetTheme('dark')}>
                <img alt="Dark theme" src={darkTheme} />
              </ThemeButton>
            )}
            {themeMode === 'dark' && (
              <button onClick={() => handleSetTheme('light')}>
                <LightImg alt="Dark theme" src={lightTheme} />
              </button>
            )}
          </Buttons>
        </Right>
        <MenuButton onClick={() => setIsOpen(!isOpen)} open={isOpen}>
          {isOpen ? <CloseIcon className="" /> : <MenuIcon />}
        </MenuButton>
        <Tokenomic isOpen={isTokenomicOpen} onClose={() => setTokenomicOpen(false)} />
      </Container>
    </Page>
  );
};
