import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import { STORES_VERSION } from '@/constants';

export const ONBOARDING_DEFAULT_STATE: OnboardState = {
  step: undefined,
};

export type OnboardState = {
  step: 'pay-info' | 'email-send' | 'last-chance' | 'thank-you' | undefined
};
export const useOnboardState = create(
  persist<OnboardState>(() => ONBOARDING_DEFAULT_STATE, {
    name: 'theme-storage',
    storage: createJSONStorage(() => localStorage),
    version: STORES_VERSION,
  }),
);

export const setOnboardingStep = (step: OnboardState['step']) => useOnboardState.setState({ step });
