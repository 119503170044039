import React from 'react';
import Skeleton from 'react-loading-skeleton';
import classNames from 'classnames';

import CloseIcon from '@/assets/icons/close2.svg?react';

export interface PopupProps extends React.PropsWithChildren {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  isLoading?: boolean
  isRequired?: boolean
}

const Popup: React.FC<PopupProps> = ({
  children, isOpen, setIsOpen, isRequired, isLoading,
}) => {
  const handleBackdropClick = () => {
    if (isRequired) {
      return;
    }
    setIsOpen(false);
  };

  return (
    <div
      onClick={handleBackdropClick}
      className={classNames(
        'pointer-events-none fixed left-0 top-0 z-modal flex h-[100svh] w-[100svw] items-center justify-center',
        {
          'pointer-events-auto': isOpen,
          'pointer-events-none': isOpen,
        },
      )}
    >
      <div
        className={classNames(
          'fixed bottom-0 left-0 right-0 top-0 z-10 bg-[rgba(var(--main-black-rgb),0.5)] opacity-0 transition-[opacity_0.15sec]',
          {
            'opacity-100': isOpen,
          },
        )}
      />
      <div
        onClick={(e) => e.stopPropagation()}
        className={classNames(
          'relative z-20 flex max-w-[409px] flex-col gap-5 rounded-xl border border-solid border-[rgba(var(--onboardingBr-rgb),0.08)] bg-[var(--onboardingBg)] p-6 opacity-0',
          {
            'animate-wiggleBottomFadeInForwards': isOpen,
          },
        )}
      >
        <div
          className={classNames(
            'pointer-events-none absolute bottom-0 left-0 right-0 top-0 z-20 cursor-progress opacity-0',
            {
              'pointer-events-auto opacity-100': isLoading,
            },
          )}
        >
          <Skeleton
            baseColor="#D1D1C9"
            className="h-full w-full rounded-xl"
            highlightColor="#BCBCAF"
            style={{ borderRadius: '12px', opacity: 1 }}
          />
        </div>
        {!isRequired && (
          <button className="absolute right-3 top-3" onClick={() => setIsOpen(false)} type="button">
            <CloseIcon />
          </button>
        )}
        {children}
      </div>
    </div>
  );
};

export default Popup;
