import { PublicKey } from '@solana/web3.js';

import { axiosInstance } from './baseApi';

import { API_URL } from '@/config';

export type SolanaSignMessageReq = {
  message: string
  publicKey: PublicKey
  signature: string
  userId: number
};
export const solanaSignMessage = async ({
  signature, message, publicKey, userId,
}: SolanaSignMessageReq) => {
  const res = await axiosInstance.post(`${API_URL}/api/accounts`, {
    signature,
    message,
    public_key: publicKey,
    user: userId,
  });

  return res.data;
};
