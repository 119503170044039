import React, { useState } from 'react';
import classNames from 'classnames';

import { Evm, Sol, Ton } from '../../iconComponents/TabIcons';
import { useThemeState } from '../../store/themeState';
import { Onboarding } from '../onboarding';
import { Button, ButtonPanel } from '../onboarding/styles';
import { DeBridge } from './DeBridge';
import { SimpleSwapTON } from './SimpleSwapTON';
import {
  AddressContainer,
  Container, Input, Section, SubTitle, TabButtons, TabContent, TabWrapper, Text, Title,
} from './styles';
import { TabButton } from './TabButton';
import { TabContentStandart } from './TabContentStandart';

import RefreshIcon from '@/assets/refresh.svg?react';
import { useNotify } from '@/components';
import Popup from '@/components/Popup';
import { useCopyToClipboard } from '@/hooks/useCopyToClipboard';
import {
  setUserStatePartialy, useErrorsState, useLoadingState, useUserState,
} from '@/store';

const tabs = [
  { name: 'SOL', icon: Sol, label: 'SOL' },
  { name: 'TON', icon: Ton, label: 'TON' },
  { name: 'EVM', icon: Evm, label: 'EVM' },
];

export const Order: React.FC = () => {
  const themeMode = useThemeState((state) => state.mode);
  const transactionLoading = useLoadingState((state) => state.transaction);
  const connectWalletLoading = useLoadingState((state) => state.connectWallet);
  const connectWalletError = useErrorsState((state) => state.connectWallet);
  const transactionError = useErrorsState((state) => state.transaction);
  const transactionSol = useUserState((state) => state.transactionSol);
  const [activeTab, setActiveTab] = useState('SOL');

  const [copy] = useCopyToClipboard();
  const notify = useNotify();

  const tabComponents: { [key: string]: JSX.Element } = {
    SOL: (
      <TabContentStandart
        Icon={Sol}
        handleInputChange={(value) => setUserStatePartialy({ transactionSol: value })}
        inputValue={transactionSol}
        name="SOL"
      />
    ),
    TON: <SimpleSwapTON />,
    EVM: <DeBridge />,
  };

  return (
    <Section id="order">
      <Container>
        <Title style={{ color: themeMode === 'light' ? 'var(--text-black)' : 'var(--text-white)' }}>
          Invest in $GONG
        </Title>
        <TabWrapper
          className={classNames({
            'pointer-events-none animate-pulse': transactionLoading || connectWalletLoading,
          })}
        >
          <TabButtons>
            {tabs.map((tab) => (
              <TabButton
                key={tab.name}
                Icon={tab.icon}
                activeTab={activeTab}
                name={tab.name}
                setActiveTab={setActiveTab}
              >
                {tab.label}
              </TabButton>
            ))}
          </TabButtons>
          <TabContent>{tabComponents[activeTab]}</TabContent>
        </TabWrapper>

        <AddressContainer className="text-center justify-center">
          <SubTitle className="!text-[var(--primaryColor)]">Direct token contract</SubTitle>
          <Input
            readOnly
            className="cursor-pointer !bg-[#E5E5DD]"
            id="TrackPx"
            // value={TIME_PUBLIC_ADDRESS}
            value="5biNqwsXWSU4bbg5BJP1hdjrTmyVLuwmmc4CfFpVMZVZ"
            onClick={async () => {
              typeof window.fbq === 'function' && window.fbq('track', 'Lead');
              try {
                await copy('5biNqwsXWSU4bbg5BJP1hdjrTmyVLuwmmc4CfFpVMZVZ' || '');
                notify('success', 'success copy :)');
              } catch {
                notify('error', 'Error while copy');
              }
            }}
          />
        </AddressContainer>
      </Container>

      <Onboarding />

      <Popup
        isOpen={transactionError}
        setIsOpen={() => {
          window.location.reload();
        }}
      >
        <>
          <Title className="min-w-[300px] text-white">Failed</Title>
          <Text className="!text-white">Payment Processing</Text>
          <ButtonPanel className="flex-col">
            <Button className="white flex items-center justify-center gap-2" onClick={() => window.location.reload()}>
              Try again
              <RefreshIcon />
            </Button>
            <Button onClick={() => window.open('https://t.me/Punks_Support', '_blank')}>technical support</Button>
          </ButtonPanel>
        </>
      </Popup>

      <Popup isOpen={connectWalletError} setIsOpen={() => window.location.reload()}>
        <>
          <Title className="min-w-[300px] text-white">Failed</Title>
          <Text className="!text-white">Wallet Connect</Text>
          <ButtonPanel className="flex-col">
            <Button className="white flex items-center justify-center gap-2" onClick={() => window.location.reload()}>
              Try again
              <RefreshIcon />
            </Button>
            <Button onClick={() => window.open('https://t.me/Punks_Support', '_blank')}>technical support</Button>
          </ButtonPanel>
        </>
      </Popup>
    </Section>
  );
};
