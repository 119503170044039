import React, { FC } from 'react';

import { TabButtonsItem } from './styles';

type TabButtonProps = {
  Icon: FC
  activeTab: string
  children: React.ReactNode
  name: string
  setActiveTab: (name: string) => void
};

export const TabButton: FC<TabButtonProps> = ({
  name, setActiveTab, activeTab, Icon, children,
}) => (
  <TabButtonsItem className={activeTab === name ? 'active' : ''} onClick={() => setActiveTab(name)}>
    <Icon />
    {children}
  </TabButtonsItem>
);
