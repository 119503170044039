import { FiExternalLink } from 'react-icons/fi';
import styled from 'styled-components';

export const Notification = styled.span`
    display: flex;
    align-items: center;
`;

export const StyledLink = styled.a`
    color: #ffffff;
    display: flex;
    align-items: center;
    margin-left: 16px;
    text-decoration: underline;

    &:hover {
        color: #000000;
    }
`;

export const StyledLaunchIcon = styled(FiExternalLink)`
    font-size: 20px;
    margin-left: 8px;
`;
