import React from 'react';
import { useWallet } from '@solana/wallet-adapter-react';

import { AddressContainer, Input, SubTitle } from './styles';

import { useNotify } from '@/components';
import { useCopyToClipboard } from '@/hooks/useCopyToClipboard';

export const SimpleSwapTON: React.FC = () => {
  function getWidth() {
    if (document.documentElement.clientWidth > 998) return 566;
    if (document.documentElement.clientWidth <= 998) return 260;
  }
  const [copy] = useCopyToClipboard();
  const width = getWidth();
  const { publicKey } = useWallet();
  const notify = useNotify();

  return (
    <div className="flex flex-col gap-3">
      {publicKey
      && (
      <AddressContainer>
        <SubTitle>Your Solana address</SubTitle>
        <Input
          readOnly
          className="cursor-pointer"
          value={publicKey?.toBase58()}
          onClick={async () => {
            try {
              await copy(publicKey?.toBase58() || '');
              notify('success', 'success copy :)');
            } catch {
              notify('error', 'Error while copy');
            }
          }}
        />
      </AddressContainer>
      )}

      <iframe
        frameBorder="0"
        height="392px"
        id="simpleswap-frame"
        name="SimpleSwap Widget"
        src="https://simpleswap.io/widget/06989838-e591-4f41-a48f-2672c6d26f65"
        title="ton swap"
        width={`${width}px`}
      />
    </div>
  );
};
