import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import { STORES_VERSION } from '@/constants';

export const THEME_DEFAULT_STATE = {
  mode: 'light',
} as const;

export type ThemeState = {
  mode: 'dark' | 'light'
};
export const useThemeState = create(
  persist<ThemeState>(() => THEME_DEFAULT_STATE, {
    name: 'theme-storage',
    storage: createJSONStorage(() => localStorage),
    version: STORES_VERSION,
  }),
);

export const setThemeMode = (mode: ThemeState['mode']) => useThemeState.setState({ mode });
