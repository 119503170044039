import { useCallback } from 'react';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import type { TransactionSignature } from '@solana/web3.js';
import {
  LAMPORTS_PER_SOL, PublicKey, SystemProgram, Transaction,
} from '@solana/web3.js';

import { useNotify } from './Notify';

export const useSendTransaction = () => {
  const { connection } = useConnection();
  const { publicKey, sendTransaction } = useWallet();
  const notify = useNotify();

  const onClick = useCallback(
    async (lamports: number, publicAddress: string) => {
      let signature: TransactionSignature | undefined;
      try {
        if (!publicKey) throw new Error('Wallet not connected!');

        const lamportsI = LAMPORTS_PER_SOL * lamports;

        const transaction = new Transaction().add(
          SystemProgram.transfer({
            fromPubkey: publicKey,
            toPubkey: new PublicKey(publicAddress),
            lamports: lamportsI,
          }),
        );

        signature = await sendTransaction(transaction, connection);
        notify('info', 'Transaction sent:', signature);

        await connection.confirmTransaction(signature, 'processed');
        notify('success', 'Transaction successful!', signature);
      } catch (error: any) {
        notify('error', `Transaction failed! ${error?.message}`, signature);
        throw error;
      }
    },
    [publicKey, connection, sendTransaction, notify],
  );

  return onClick;
};
