import React, { useEffect, useRef } from 'react';
import Panzoom, { PanzoomObject } from '@panzoom/panzoom';

import diagram from '../../assets/diagram.png';
import close from '../../assets/icons/close.svg';
import {
  ModalBackdrop, ModalButtonClose, ModalContainer, ModalHeader,
  ZoomableContainer,
} from './styles';

export interface ModalProps {
  isOpen: boolean
  onClose: () => void
}

export const Tokenomic: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const panzoomInstanceRef = useRef<PanzoomObject | null>(null);

  useEffect(() => {
    function initializePanzoom() {
      if (contentRef.current) {
        panzoomInstanceRef.current = Panzoom(contentRef.current as HTMLElement, {
          maxScale: 4,
          minScale: 1,
          contain: 'outside',
        });
        contentRef.current.addEventListener('wheel', panzoomInstanceRef.current.zoomWithWheel);
        panzoomInstanceRef.current.zoom(1.0, { animate: false });
      }
    }

    if (isOpen && contentRef.current && !panzoomInstanceRef.current) {
      const imgElement = contentRef.current.querySelector('img');
      if (imgElement && imgElement.complete) {
        initializePanzoom();
      } else if (imgElement) {
        imgElement.onload = initializePanzoom;
      }
    }

    return () => {
      if (panzoomInstanceRef.current) {
        panzoomInstanceRef.current.destroy();
        panzoomInstanceRef.current = null;
      }
    };
  }, [isOpen]);

  useEffect(() => {
    const originalOverflow = document.body.style.overflow;
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = originalOverflow;
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <ModalBackdrop onClick={onClose}>
      <ModalContainer onClick={(e) => e.stopPropagation()}>
        <ModalButtonClose onClick={onClose}>
          <img alt="Close modal" src={close} />
        </ModalButtonClose>
        <ModalHeader>How the $GONG token will be used in the game?</ModalHeader>
        <ZoomableContainer>
          <div ref={contentRef}>
            <img alt="Diagram" src={diagram} />
          </div>
        </ZoomableContainer>
      </ModalContainer>
    </ModalBackdrop>
  );
};
