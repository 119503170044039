import React, { CSSProperties, useEffect } from 'react';
import { Outlet } from '@tanstack/react-router';
import { SnackbarProvider } from 'notistack';

import { Notifications } from './widgets/notifications';
import { SolanaWalletAdapter } from './components';

import '@/styles/index.scss';
import './App.scss';

import { createUser, useUserState } from '@/store';
import { useThemeState } from '@/store/themeState';
import { Footer } from '@/widgets/footer';
import { Header } from '@/widgets/header';

export const App: React.FC = () => {
  const themeMode = useThemeState((state) => state.mode);
  const user = useUserState((state) => state.user);

  useEffect(() => {
    if (user) {
      return;
    }
    createUser();
  }, []);

  return (
    <SolanaWalletAdapter>
      <SnackbarProvider>
        <div
          style={
            {
              '--themeBg': themeMode === 'light' ? 'var(--main-white)' : 'var(--main-black)',
              background: themeMode === 'light' ? 'var(--main-white)' : 'var(--main-black)',
              '--primaryColor': themeMode === 'light' ? 'rgb(35, 31, 32)' : 'rgb(255, 255, 255)',
            } as CSSProperties
          }
        >
          <Header />
          <Outlet />
          <Footer />
          <Notifications />
        </div>
      </SnackbarProvider>
    </SolanaWalletAdapter>
  );
};
