import React, { useEffect } from 'react';
import { useNavigate } from '@tanstack/react-router';

import explane1 from '@/assets/explane-1.png';
import explane2 from '@/assets/explane-2.png';
import explane3 from '@/assets/explane-3.png';
import explane4 from '@/assets/explane-4.png';
import explane5 from '@/assets/explane-5.png';
import explane6 from '@/assets/explane-6.png';
import punkinomics1 from '@/assets/punkinomics/punkinomics-1.png';
import punkinomics2 from '@/assets/punkinomics/punkinomics-2.png';
import punkinomics3 from '@/assets/punkinomics/punkinomics-3.png';
import punkinomics4 from '@/assets/punkinomics/punkinomics-4.png';
import punkinomics5 from '@/assets/punkinomics/punkinomics-5.png';
import punkinomics6 from '@/assets/punkinomics/punkinomics-6.png';
import { useThemeState } from '@/store';

export const MainPage: React.FC = () => {
  const themeMode = useThemeState((state) => state.mode);
  const navigate = useNavigate();
  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    window.addEventListener('resize', () => {
      vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
  }, []);
  useEffect(() => {
    if (themeMode === 'dark') {
      document.body.classList.add('dark-page');
      document.querySelector('.punkinomics .container')?.classList.add('inverted');

      return;
    }

    document.body.classList.remove('dark-page');
    document.querySelector('.punkinomics .container')?.classList.remove('inverted');
  }, [themeMode]);

  return (
    <>
      <main className="index">
        <section className="first-sc" id="home">
          <div className="first-sc__container container">
            <a className="first-sc__link" href="https://play.wepunks.wtf" rel="noreferrer" target="_blank">
              <h1 className="first-sc__title">
                Web3 App
                <br />
                that values your time
              </h1>
              <p className="first-sc__text">Press to Play Airdrop Game</p>
            </a>
          </div>
        </section>
        <section className="roadmap" id="roadmap">
          <div className="container">
            <h2 className="roadmap__title">Roadmap</h2>
            <ul className="roadmap-list">
              <li className="roadmap-list__item">
                <h3 className="roadmap-list__title">Stage 1</h3>
                <ul className="roadmap-list__sublist">
                  <li className="roadmap-list__subitem">
                    <h4 className="roadmap-list__subtitle">Kick-off the $PUNKS Airdrop Game</h4>
                    <p className="roadmap-list__text">
                      Housemade sesame pancake bun, shroom ragu, Chinese slaw, cilantro.............$10
                    </p>
                  </li>
                  <li className="roadmap-list__subitem">
                    <h4 className="roadmap-list__subtitle">CHAOS CHILI PUNK</h4>
                    <p className="roadmap-list__text">
                      Vegan sausage (Or not), fiery chili with black beans, corn, habanero peppers....$20
                    </p>
                  </li>
                  <li className="roadmap-list__subitem">
                    <h4 className="roadmap-list__subtitle">(It&apos;s worth a taste)</h4>
                    <p className="roadmap-list__text">
                      Sisar wif schrimps
                      <br />
                      Garbage seagull
                      <br />
                      Pizzas&nbsp;$TIME....10.000&nbsp;btc
                      <br />
                      (GF)
                    </p>
                  </li>
                </ul>
              </li>

              <li className="roadmap-list__item">
                <h3 className="roadmap-list__title">Stage 2</h3>
                <ul className="roadmap-list__sublist">
                  <li className="roadmap-list__subitem">
                    <h4 className="roadmap-list__subtitle">Listing</h4>
                    <p className="roadmap-list__text">
                      Liquidity successfully burned
                      <br />
                      7:25 (am) oh fuck
                    </p>
                  </li>

                  <li className="roadmap-list__subitem">
                    <h4 className="roadmap-list__subtitle">CEX listdrinks</h4>
                    <p className="roadmap-list__text">
                      2B Market Cup
                      <br />
                      FOMO juice
                      <br />
                      SLERF tears
                    </p>
                  </li>

                  <li className="roadmap-list__subitem">
                    <h4 className="roadmap-list__subtitle">$Punks Game into RPG</h4>
                    <p className="roadmap-list__text">
                      (Real Punks Game) where you can steal coins, battle, die, and&nbsp;be&nbsp;reborn&nbsp;..$14
                    </p>
                  </li>

                  <li className="roadmap-list__subitem">
                    <h4 className="roadmap-list__subtitle">Web service for ambassadors</h4>
                    <p className="roadmap-list__text">
                      Creamy coconut curry with tofu, mixed vegetables (GF)&nbsp;..................$9
                    </p>
                  </li>

                  <li className="roadmap-list__subitem">
                    <h4 className="roadmap-list__subtitle">Finger tacos with cheese</h4>
                    <p className="roadmap-list__text">
                      Crispy taco shells filled with creamy mac and cheese&nbsp;..................$8
                    </p>
                  </li>
                </ul>
              </li>

              <li className="roadmap-list__item">
                <h3 className="roadmap-list__title">Stage 3</h3>
                <ul className="roadmap-list__sublist">
                  <li className="roadmap-list__subitem">
                    <h4 className="roadmap-list__subtitle">Fucking Hype</h4>
                    <p className="roadmap-list__text">
                      Create the Play, Stake, Hold, Share, Live, and other aspects of existence to Earn Blockchain
                      controlled by AI
                    </p>
                  </li>

                  <li className="roadmap-list__subitem">
                    <h4 className="roadmap-list__subtitle">Launch our own AI music label</h4>
                    <p className="roadmap-list__text">
                      That&apos;s going to be hard to swallow... But... anyone can make their own song with their own
                      voice in a couple of clicks and release it on the music scene
                    </p>
                  </li>

                  <li className="roadmap-list__subitem">
                    <h4 className="roadmap-list__subtitle">Buterin dance</h4>
                    <p className="roadmap-list__text">Crisp</p>
                  </li>

                  <li className="roadmap-list__subitem">
                    <h4 className="roadmap-list__subtitle">Netflix series about us</h4>
                    <p className="roadmap-list__text">Several Grammys and a few Oscars</p>
                    <p className="roadmap-list__text_br">(GF) = gluten free option</p>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </section>

        <section className="punkinomics" id="explane">
          <div className="container">
            <h2 className="punkinomics__title pb-5">WebApp PreView</h2>
            {/* <h2 className="punkinomics__title">WebApp PreView</h2> */}
            <ul className="punkinomics-list">
              {[{
                img: explane1,
                title: 'Earn $PUNKS by clicking!',
              },
              {
                img: explane2,
                title: 'Complete tasks to earn more',
              },
              {
                img: explane3,
                title: 'Stake $GONG with high APY!',
              },
              {
                img: explane4,
                title: 'Invite friends, earn $GONG',
              },
              {
                img: explane5,
                title: 'Create band and enter war!',
              },
              {
                img: explane6,
                title: 'Get stronger, buy Boosts!',
              }].map((item) => (
                <li key={item.title} className="punkinomics-list__item">
                  <div className="flex flex-col gap-3">
                    <h3 className="punkinomics-list__title !text-[18px] md:!text-[20px] pb-[40px]">{item.title}</h3>
                  </div>
                  <div className="punkinomics-list-img">
                    <img
                      alt="punkinomics 1"
                      className="punkinomics-list-img__food"
                      height="540"
                      loading="lazy"
                      src={item.img}
                      width="566"
                    />
                  </div>

                </li>
              ))}
            </ul>
          </div>
        </section>
      </main>
    </>
  );
};
