import axios from 'axios';

import { API_URL } from '@/config';

export const axiosInstance = axios.create({
  baseURL: API_URL,
  timeout: 15000,
  withCredentials: true,
});

export const sleep = (delay: number) => new Promise((res) => {
  setTimeout(() => {
    res(true);
  }, delay);
});
