import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import { UserStore } from './types';

import { STORES_VERSION } from '@/constants';

export const USER_STATE_DEFAULT_STATE = {
  lastFetched: 0,
  user: null,
  transactionSol: '0.1',
  token_price: 0,
  deposit: 0,
};
export const useUserState = create(
  persist<UserStore>(() => USER_STATE_DEFAULT_STATE, {
    name: 'user-state-storage',
    storage: createJSONStorage(() => localStorage),
    version: STORES_VERSION,
  }),
);
