import { createRootRoute, createRoute, createRouter } from '@tanstack/react-router';

import { MainPage } from './pages/MainPage';
import { PresalePage } from './pages/PresalePage';
import { App } from './App';

const rootRoute = createRootRoute({
  component: () => <App />,
});

const indexRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/',
  component: () => <MainPage />,
});

const presaleRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/token',
  component: () => <PresalePage />,
});

const routeTree = rootRoute.addChildren([indexRoute, presaleRoute]);

export const router = createRouter({ routeTree });

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}
