import { create } from 'zustand';

import { LoadingState } from './types';

export const LOADING_DEFAULT_STATE = {
  connectWallet: false,
  transaction: false,
  user: false,
  userBalance: false,
  tokenPrice: false,
};
export const useLoadingState = create<LoadingState>(() => LOADING_DEFAULT_STATE);
