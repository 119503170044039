import { CSSProperties } from 'react';
import classNames from 'classnames';

import '@/styles/index.scss';

import { removeNotification, useNotifications } from '@/store';

export function Notifications() {
  const notificationsList = useNotifications((state) => state.list);

  return (
    <div className="pointer-events-none fixed bottom-0 left-0 right-0 top-0 z-notifications flex max-w-full flex-col items-center gap-2 break-all">
      {notificationsList.map((notification, index) => (
        <div
          key={notification.id}
          onClick={() => removeNotification(notification.id)}
          style={{ '--offset': `calc(40px + ${index * 40}px)`, '--index': index + 30 } as CSSProperties}
          className={classNames(
            'pointer-events-all absolute bottom-[var(--offset)] left-10 z-[var(--index)] flex items-center  justify-center rounded-xl p-3 text-[15px] text-[#F7F7ED] transition-[top_0.15sec]',
            {
              'bg-[#383c2c]': notification.mode === 'success',
              'bg-[#f52a3c]': notification.mode === 'error',
              'animate-notificationIn': notification.animation === 'in',
              'animate-notificationOut': notification.animation === 'out',
            },
          )}
        >
          {notification.content}
        </div>
      ))}
    </div>
  );
}
